import styled from "styled-components";

export const CoverContainer = styled.div`
  display: flex;
  background-image: ${({background}) => background};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  min-height: 150px;
  font-size: 42px;
  font-weight: 300;
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 33px;
  line-height: 30px;
  color: var(--subtitle-color);

  &:before {
    content: '';
    border-bottom: 3px solid #0e6faa;
    width: 50px;
    display: block;
    margin-bottom: 33px;
    border-radius: 2px;
  }
`

export const Description = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
  color: var(--text-color);
  
  & ul {
    list-style-type: disc;
  }
`
