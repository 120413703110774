import React from "react";

import {DrvLinkPath} from "../Shared/DrvLink/DrvLinkPath";
import {TermsOfUseData} from "./TermsOfUse.data";
import {CoverContainer, Description, Title} from "./TermsOfUse.styles";

const TermsOfUse = () => {
    return (
        <>
            {/*CONTENT*/}
            <CoverContainer background='linear-gradient(268deg, #f16266, #bc474c)'>
                <div className='container mx-auto flex flex-col justify-center'>
                    {TermsOfUseData[0].title}
                </div>
            </CoverContainer>

            {/*CONTENT*/}
            <div className='container mx-auto py-10'>
                <DrvLinkPath items={[{ text: 'Drivosity', link: '/', isLink: true }, { text: 'Terms of Use', link: '/terms-of-use', isLink: false }]} />
            </div>

            {/*CONTENT*/}
            <div className='container mx-auto'>
                <div className='mb-10'>
                    <div className='drv-subtitle'>
                        {TermsOfUseData[1].title}
                    </div>

                    <Description>
                        {TermsOfUseData[1].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[2].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[2].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[3].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[3].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[4].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[4].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[5].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[5].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[6].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[6].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[7].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[7].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[8].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[8].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[9].title}
                    </Title>

                    <Description>
                        <div className='mb-7'>
                            {TermsOfUseData[9].content}
                        </div>
                        <div className='mb-7 px-5'>
                            <ul>
                                {TermsOfUseData[10].content}
                            </ul>
                        </div>
                        {TermsOfUseData[11].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[12].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[12].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[13].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[13].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[14].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[14].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[15].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[15].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[16].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[16].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[17].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[17].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[18].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[18].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[19].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[19].content}
                    </Description>
                </div>

                <div className='mb-10'>
                    <Title>
                        {TermsOfUseData[20].title}
                    </Title>

                    <Description>
                        {TermsOfUseData[20].content}
                    </Description>
                </div>
            </div>
        </>
    )
}

export default TermsOfUse;
