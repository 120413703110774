import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import TermsOfUse from "../components/TermsOfUse/TermsOfUse";

import Favicon from "../images/Favicon.png";

const TermsOfUsePage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Terms of Use - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="Review Drivosity's Terms of Use to understand the conditions for using our website. Includes policies on intellectual property, copyright, trademarks, user conduct, and legal disclaimers. Access detailed terms and conditions here."/>
            </Helmet>
            <Layout component={<TermsOfUse/>}/>
        </>
    )
}

export default TermsOfUsePage;
